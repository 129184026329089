<template>
  <v-data-table
    :headers="headers"
    :items="tickets"
    :items-per-page="100"
    :options.sync="options"
    :server-items-length="total"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right',
      itemsPerPageOptions: [10, 25, 50, 100],
    }"
    :loading="loading"
    multi-sort
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
        color="white"
      >
        <v-toolbar-title>{{ $t("lbl.tickets") }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="auth('createTickets')"
              color="primary"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              :disabled="disable"
            >{{
              $t("lbl.newTicket")
            }}</v-btn>
          </template>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      v-if="editedIndex === -1"
                    >
                      <v-text-field
                        v-model="editedItem.subject"
                        :rules="rules.required"
                        :label="$t('lbl.subject')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="editedIndex === -1"
                    >
                      <v-textarea
                        v-model="editedItem.message"
                        :rules="rules.required"
                        :label="$t('lbl.message')"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-if="editedIndex !== -1"
                        v-model="editedItem.status"
                        :items="ticket"
                        :label="$t('lbl.status')"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="reset"
                >{{ $t("lbl.clear") }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >{{
                  $t("lbl.cancel")
                }}</v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="!valid"
                  @click="save"
                >{{
                  $t("lbl.send")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-text-field
              outlined
              dense
              hide-details
              v-model="filter.ticket"
              :label="$t('lbl.ticketSearch')"
              @keydown.enter="initialize(true)"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="auth('getUsers')"
            cols="12"
            sm="6"
            md="2"
          >
            <v-autocomplete
              outlined
              dense
              hide-details
              v-model="filter.user"
              :items="users"
              item-text="email"
              item-value="id"
              :label="$t('lbl.user')"
              @change="initialize(true)"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-select
              outlined
              dense
              hide-details
              v-model="filter.status"
              :items="ticket"
              :label="$t('lbl.status')"
              @change="initialize(true)"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-datetime-picker
              v-model="filter.start"
              :label="$t('lbl.start')"
              :clearText="$t('lbl.clear')"
              :okText="$t('lbl.ok')"
              :textFieldProps="{
                outlined: true,
                dense: true,
                hideDetails: true,
                clearable: true,
              }"
              :datePickerProps="{ locale: $i18n.locale, 'no-title': true }"
              :timePickerProps="{ format: '24hr', 'no-title': true }"
              @input="initialize(true)"
            ></v-datetime-picker>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-datetime-picker
              v-model="filter.end"
              :label="$t('lbl.end')"
              :clearText="$t('lbl.clear')"
              :okText="$t('lbl.ok')"
              :textFieldProps="{
                outlined: true,
                dense: true,
                hideDetails: true,
                clearable: true,
              }"
              :datePickerProps="{ locale: $i18n.locale, 'no-title': true }"
              :timePickerProps="{ format: '24hr', 'no-title': true }"
              @input="initialize(true)"
            ></v-datetime-picker>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            sm="12"
            md="2"
          >
            <v-btn
              block
              color="warning"
              @click="refresh"
            >
              <v-icon>mdi-refresh-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:[`item.no`]="{ item }">
      <v-btn
        link
        small
        rounded
        elevation="2"
        color="primary"
        :to="'/ticket/' + item.no.toString()"
      >
        {{ item.no }}
      </v-btn>
    </template>
    <template v-slot:[`item.user`]="{ item }">
      <span>
        {{ item.user.email }}
      </span>
    </template>
    <template v-slot:[`item.subject`]="{ item }">
      <span>
        {{ item.subject &&  item.subject.replace(/(.{45})..+/, "$1…")  }}
      </span>
    </template>
    <template v-slot:[`item.message`]="{ item }">
      <span>
        {{ item.message &&  item.message.replace(/(.{75})..+/, "$1…")  }}
      </span>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <span>{{ getEnumText(ticket, item.status) }}</span>
    </template>
    <template v-slot:[`item.date`]="{ item }">
      <span>
        {{ item.date && $luxon.fromISO(item.date).toLocaleString(fmt) }}
      </span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        class="mr-2"
        :disabled="disable"
        @click="editItem(item)"
      > mdi-pencil </v-icon>
      <v-icon
        :disabled="disable"
        @click="deleteItem(item)"
      > mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";
import { DateTime } from "luxon";
import { ticket } from "@/common/enums";
import { required } from "@/common/validation";

export default {
  data: () => ({
    dialog: false,
    disable: false,
    valid: true,
    loading: true,
    options: {},
    total: 0,
    tickets: [],
    users: [],
    fmt: DateTime.DATETIME_SHORT,
    editedIndex: -1,
    editedItem: {
      subject: "",
      message: "",
    },
    defaultItem: {
      subject: "",
      message: "",
    },
    rules: {
      required: [required],
    },
    filter: {
      ticket: null,
      user: null,
      status: null,
      start: null,
      end: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("lbl.newTicket")
        : this.$t("lbl.editTicket");
    },
    headers() {
      const columns = [
        { text: this.$t("lbl.no"), value: "no", width: "10%" },
        { text: this.$t("lbl.subject"), value: "subject", width: "20%" },
        { text: this.$t("lbl.message"), value: "message", width: "30%" },
        { text: this.$t("lbl.status"), value: "status", width: "10%" },
        { text: this.$t("lbl.date"), value: "date", width: "10%" },
      ];
      if (this.auth("getUsers")) {
        columns.splice(4, 0, {
          text: this.$t("lbl.user"),
          value: "user.email",
        });
      }
      if (this.auth("manageTickets")) {
        columns.push({
          text: this.$t("lbl.actions"),
          value: "actions",
          align: "center",
          sortable: false,
        });
      }
      return columns;
    },
    ticket() {
      return ticket();
    },
  },

  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions("ticket", [
      "findTickets",
      "getTicket",
      "saveTicket",
      "deleteTicket",
    ]),
    ...mapActions("user", ["findUsers"]),

    async initialize(search) {
      this.loading = true;
      if (search) {
        this.options.page = 1;
      }
      const filters = this.getFilters(this.options, this.filter);
      try {
        const { results, totalResults } = await this.findTickets(filters);
        this.tickets = results;
        this.total = totalResults;
      } catch (error) {
        this.$toasted.error(this.getMessageText(error.message));
      } finally {
        this.loading = false;
      }

      if (this.auth("getUsers")) {
        try {
          const { results } = await this.findUsers({
            limit: 0,
          });
          this.users = results;
        } catch (error) {
          this.$toasted.error(this.getMessageText(error.message));
        }
      }
    },

    reset() {
      this.$refs.form.reset();
    },

    close() {
      this.reset();
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    color(check) {
      return check ? "success" : "error";
    },

    editItem(item) {
      this.editedIndex = this.tickets.indexOf(item);
      const { user, ...body } = { ...item };
      this.editedItem = { ...body, user: user.id };
      this.dialog = true;
    },

    deleteItem(item) {
      this.$swal({
        html: this.$t("msg.deleteText", { item: item.no }),
        icon: "warning",
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("lbl.delete"),
        cancelButtonText: this.$t("lbl.cancel"),
      }).then((result) => {
        if (result.value) {
          this.disable = true;
          this.deleteTicket(item.id)
            .then(() => {
              this.initialize();
              this.$toasted.success(
                this.$t("msg.delete", { item: this.$t("lbl.ticket") }),
              );
            })
            .catch((error) => {
              this.$toasted.error(this.getMessageText(error.message));
            })
            .finally(() => {
              this.disable = false;
            });
        }
      });
    },

    save() {
      this.dialog = false;
      this.disable = true;
      const editedItem = { ...this.editedItem };
      return this.saveTicket(editedItem)
        .then(() => {
          this.initialize();
          this.$toasted.success(
            this.$t("msg.save", { item: this.$t("lbl.ticket") }),
          );
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        })
        .finally(() => {
          this.disable = false;
        });
    },

    refresh() {
      Object.keys(this.filter).forEach((i) => {
        this.filter[i] = null;
      });
      this.initialize();
    },
  },
};
</script>

<style>
.swal2-html-container {
  font-size: 26px !important;
}
.swal2-checkbox,
.swal2-radio {
  font-size: 22px;
  color: red !important;
}
</style>
