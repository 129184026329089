import { TicketService } from "@/common/api.service";

const initialState = {};

const state = { ...initialState };

const getters = {};

const mutations = {};

const actions = {
  findTickets(_, params) {
    return TicketService.find(params).then(({ data }) => data);
  },
  getTicket(_, no) {
    return TicketService.get(no).then(({ data }) => data);
  },
  saveTicket(_, {
    id, no, date, user, createdAt, updatedAt, ...body
  }) {
    const request = id
      ? TicketService.update(id, body)
      : TicketService.create(body);

    return request.then(({ data }) => data);
  },
  deleteTicket(_, id) {
    return TicketService.delete(id).then(({ data }) => data);
  },
  saveMessage(_, body) {
    return TicketService.saveMessage(body).then(({ data }) => data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
