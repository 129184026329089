/* eslint-disable import/no-named-as-default-member */
import i18n from "../i18n";
import rx from "./regex";

export const required = (v) => !(v == null || v === "") || i18n.t("val.required");
export const minLength = (number) => (v) => (v || "").length >= number || i18n.t("val.minLength", { number });
export const maxLength = (number) => (v) => (v || "").length <= number || i18n.t("val.maxLength", { number });
export const email = (v) => rx.email.test(v) || i18n.t("val.email");
export const password = (v) => rx.password.test(v) || i18n.t("val.password");
export const wholeNumber = (v) => (Number.isInteger(Number(v)) && v >= 0) || i18n.t("val.wholeNumber");
export const positiveNumber = (v) => (!Number.isNaN(parseFloat(v)) && v > 0) || i18n.t("val.positiveNumber");
export const positiveInteger = (v) => (Number.isInteger(Number(v)) && v > 0) || i18n.t("val.positiveInteger");
