<template>
  <div>
    <Validation />
  </div>
</template>
<script>
import Validation from "@/components/Validation.vue";

export default {
  components: {
    Validation,
  },
};
</script>
