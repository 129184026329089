import { VendorService } from "@/common/api.service";

const initialState = {};

const state = { ...initialState };

const getters = {};

const mutations = {};

const actions = {
  findVendors(_, params) {
    return VendorService.find(params).then(({ data }) => data);
  },
  getVendor(_, id) {
    return VendorService.get(id).then(({ data }) => data);
  },
  saveVendor(_, {
    id, createdAt, updatedAt, ...body
  }) {
    const request = id ? VendorService.update(id, body) : VendorService.create(body);

    return request.then(({ data }) => data);
  },
  deleteVendor(_, id) {
    return VendorService.delete(id).then(({ data }) => data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
