<template>
  <div>
    <Project />
  </div>
</template>
<script>
import Project from "@/components/Project.vue";

export default {
  components: {
    Project,
  },
};
</script>
