<template>
  <div>
    <Ticket />
  </div>
</template>
<script>
import Ticket from "@/components/Ticket.vue";

export default {
  components: {
    Ticket,
  },
};
</script>
