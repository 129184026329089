import { TransactionService } from "@/common/api.service";

const initialState = {};

const state = { ...initialState };

const getters = {};

const mutations = {};

const actions = {
  findTransactions(_, params) {
    return TransactionService.find(params).then(({ data }) => data);
  },
  getTransaction(_, id) {
    return TransactionService.get(id).then(({ data }) => data);
  },
  saveTransaction(_, {
    id, no, type, project, user, date, createdAt, updatedAt, ...body
  }) {
    const request = id
      ? TransactionService.update(id, body)
      : TransactionService.create(body);

    return request.then(({ data }) => data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
