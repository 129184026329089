import { UserService } from "@/common/api.service";

const initialState = {};

const state = { ...initialState };

const getters = {};

const mutations = {};

const actions = {
  findUsers(_, params) {
    return UserService.find(params).then(({ data }) => data);
  },
  getUser(_, id) {
    return UserService.get(id).then(({ data }) => data);
  },
  saveUser(_, {
    id, isEmailVerified, createdAt, updatedAt, ...body
  }) {
    const request = id
      ? UserService.update(id, body)
      : UserService.create(body);

    return request.then(({ data }) => data);
  },
  deleteUser(_, id) {
    return UserService.delete(id).then(({ data }) => data);
  },
  getProfile() {
    return UserService.getProfile().then(({ data }) => data);
  },
  updateProfile(_, body) {
    return UserService.updateProfile(body).then(({ data }) => data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
