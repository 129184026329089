var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.errors,"items-per-page":25,"options":_vm.options,"server-items-length":_vm.total,"footer-props":{
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-arrow-left',
    nextIcon: 'mdi-arrow-right',
    itemsPerPageOptions: [10, 25, 50, 100],
  },"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("lbl.validation")))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"info","disabled":_vm.disable},on:{"click":function($event){_vm.$router.push('/project', function () {})}}},[_c('v-icon',[_vm._v(" mdi-keyboard-backspace ")]),_vm._v(" "+_vm._s(_vm.$t("lbl.projects"))+" ")],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }