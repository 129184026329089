<template>
  <v-data-table
    :headers="headers"
    :items="products"
    :items-per-page="100"
    :options.sync="options"
    :server-items-length="total"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right',
      itemsPerPageOptions: [10, 25, 50, 100],
    }"
    :loading="loading"
    multi-sort
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
        color="white"
      >
        <v-toolbar-title>{{ $t("lbl.products") }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="auth('manageProducts')"
              color="primary"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              :disabled="disable"
            >{{
              $t("lbl.newProduct")
            }}</v-btn>
          </template>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        :readonly="editedIndex !== -1"
                        v-model="editedItem.sku"
                        :rules="rules.skuRules"
                        :label="$t('lbl.sku')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.name"
                        :rules="rules.nameRules"
                        :label="$t('lbl.name')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.brand"
                        :rules="rules.brandRules"
                        :label="$t('lbl.brand')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-select
                        v-model="editedItem.category"
                        :items="categories"
                        :rules="rules.categoryRules"
                        :label="$t('lbl.category')"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-select
                        v-model="editedItem.vendor"
                        :items="vendors"
                        item-text="name"
                        item-value="id"
                        :rules="rules.vendorRules"
                        :label="$t('lbl.vendor')"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="reset"
                >{{ $t("lbl.clear") }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >{{
                  $t("lbl.cancel")
                }}</v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="!valid"
                  @click="save"
                >{{
                  $t("lbl.save")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-text-field
              outlined
              dense
              hide-details
              v-model="filter.product"
              :label="$t('lbl.productSearch')"
              @keydown.enter="initialize(true)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-select
              outlined
              dense
              hide-details
              hidden
              v-model="filter.category"
              :items="categories"
              :label="$t('lbl.category')"
              @change="initialize(true)"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-select
              outlined
              dense
              hide-details
              v-model="filter.vendor"
              :items="vendors"
              item-text="name"
              item-value="id"
              :label="$t('lbl.vendor')"
              @change="initialize(true)"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-datetime-picker
              v-model="filter.start"
              :label="$t('lbl.start')"
              :clearText="$t('lbl.clear')"
              :okText="$t('lbl.ok')"
              :textFieldProps="{
                outlined: true,
                dense: true,
                hideDetails: true,
                clearable: true,
              }"
              :datePickerProps="{ locale: $i18n.locale, 'no-title': true }"
              :timePickerProps="{ format: '24hr', 'no-title': true }"
              @input="initialize(true)"
            ></v-datetime-picker>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-datetime-picker
              v-model="filter.end"
              :label="$t('lbl.end')"
              :clearText="$t('lbl.clear')"
              :okText="$t('lbl.ok')"
              :textFieldProps="{
                outlined: true,
                dense: true,
                hideDetails: true,
                clearable: true,
              }"
              :datePickerProps="{ locale: $i18n.locale, 'no-title': true }"
              :timePickerProps="{ format: '24hr', 'no-title': true }"
              @input="initialize(true)"
            ></v-datetime-picker>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            sm="6"
            md="1"
          >
            <v-btn
              block
              color="warning"
              @click="refresh"
            >
              <v-icon>mdi-refresh-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:[`item.category`]="{ item }">
      <span>{{ getEnumText(categories, item.category) }}</span>
    </template>
    <template v-slot:[`item.vendor`]="{ item }">
      <span>
        {{ item.vendor.name }}
      </span>
    </template>
    <template v-slot:[`item.date`]="{ item }">
      <span>
        {{ item.date && $luxon.fromISO(item.date).toLocaleString(fmt) }}
      </span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        class="mr-2"
        :disabled="disable"
        @click="editItem(item)"
      > mdi-pencil </v-icon>
      <v-icon
        :disabled="disable"
        @click="deleteItem(item)"
      > mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";
import { DateTime } from "luxon";
import { required } from "@/common/validation";
import { categories } from "@/common/enums";

export default {
  data: () => ({
    dialog: false,
    disable: false,
    valid: true,
    loading: true,
    options: {},
    total: 0,
    products: [],
    vendors: [],
    fmt: DateTime.DATETIME_SHORT,
    editedIndex: -1,
    editedItem: {
      sku: "",
      name: "",
      brand: "",
      category: "",
      vendor: "",
    },
    defaultItem: {
      sku: "",
      name: "",
      brand: "",
      category: "",
      vendor: "",
    },
    rules: {
      skuRules: [required],
      nameRules: [required],
      brandRules: [required],
      categoryRules: [required],
      vendorRules: [required],
    },
    filter: {
      product: null,
      vendor: null,
      category: null,
      start: null,
      end: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("lbl.newProduct")
        : this.$t("lbl.editProduct");
    },
    headers() {
      const columns = [
        { text: this.$t("lbl.sku"), value: "sku" },
        { text: this.$t("lbl.name"), value: "name" },
        { text: this.$t("lbl.brand"), value: "brand" },
        { text: this.$t("lbl.category"), value: "category" },
        { text: this.$t("lbl.vendor"), value: "vendor" },
        { text: this.$t("lbl.date"), value: "date" },
      ];
      if (this.auth("manageUsers")) {
        columns.push({
          text: this.$t("lbl.actions"),
          value: "actions",
          align: "center",
          sortable: false,
        });
      }
      return columns;
    },
    categories() {
      return categories();
    },
  },

  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions("product", [
      "findProducts",
      "getProduct",
      "saveProduct",
      "deleteProduct",
    ]),
    ...mapActions("vendor", ["findVendors"]),

    async initialize(search) {
      this.loading = true;
      if (search) {
        this.options.page = 1;
      }
      const filters = this.getFilters(this.options, this.filter);
      try {
        const { results, totalResults } = await this.findProducts(filters);
        this.products = results;
        this.total = totalResults;
      } catch (error) {
        this.$toasted.error(this.getMessageText(error.message));
      } finally {
        this.loading = false;
      }

      try {
        const { results } = await this.findVendors({
          limit: 0,
        });
        this.vendors = results;
      } catch (error) {
        this.$toasted.error(this.getMessageText(error.message));
      }
    },

    reset() {
      this.$refs.form.reset();
    },

    close() {
      this.reset();
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    color(check) {
      return check ? "success" : "error";
    },

    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      const { vendor, ...body } = { ...item };
      this.editedItem = { ...body, vendor: vendor.id };
      this.dialog = true;
    },

    deleteItem(item) {
      this.$swal({
        html: this.$t("msg.deleteText", {
          item: `${item.name} - ${item.brand}`,
        }),
        icon: "warning",
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("lbl.delete"),
        cancelButtonText: this.$t("lbl.cancel"),
      }).then((result) => {
        if (result.value) {
          this.disable = true;
          this.deleteProduct(item.id)
            .then(() => {
              this.initialize();
              this.$toasted.success(
                this.$t("msg.delete", { item: this.$t("lbl.product") }),
              );
            })
            .catch((error) => {
              this.$toasted.error(this.getMessageText(error.message));
            })
            .finally(() => {
              this.disable = false;
            });
        }
      });
    },

    save() {
      this.dialog = false;
      this.disable = true;
      const editedItem = { ...this.editedItem };
      return this.saveProduct(editedItem)
        .then(() => {
          this.initialize();
          this.$toasted.success(
            this.$t("msg.save", { item: this.$t("lbl.product") }),
          );
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        })
        .finally(() => {
          this.disable = false;
        });
    },

    refresh() {
      Object.keys(this.filter).forEach((i) => {
        this.filter[i] = null;
      });
      this.initialize();
    },
  },
};
</script>

<style>
.swal2-html-container {
  font-size: 26px !important;
}
.swal2-checkbox,
.swal2-radio {
  font-size: 22px;
  color: red !important;
}
</style>
