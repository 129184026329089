<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mt-4">
        <h2 class="font-weight-bold red--text">
          <!-- 1 Haziran itibariyle eğitim amaçlı Zoom Toplantıları yapılacaktır. -->
          {{ $t("lbl.zoom1") }}
          <br />          
        </h2>
        <h4>
          <!-- (Çarşamba günleri 16:00 - 17:00)
          <br />
          (Cumartesi günleri 14:00 - 15:00) -->
          {{ $t("lbl.zoom2") }}
        </h4>
        <v-card class="mx-auto mt-2 pl-12 text-no-wrap blue-grey lighten-4" max-width="425">
          <v-card-title>
            <h3>
              <a
                target="_blank"
                href="https://us05web.zoom.us/j/3843645278?pwd=jbId7eJpxQu9qI5RtybMJVRqhwcCu8.1"
                >{{ $t("lbl.zoom3") }}
              </a>
            </h3>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" class="mt-4">
        <v-img
          :src="require('../assets/logo-dtech.png')"
          class="my-3"
          contain
          height="350"
        />
      </v-col>

      <v-col class="mt-4">
        <h1 class="display-2 font-weight-bold mb-3 light-blue--text">
          {{ $t("lbl.logoTitle2") }}
        </h1>
        <h1 class="display-2 font-weight-bold mb-3 light-blue--text">
          {{ $t("lbl.logoTitle") }}
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="8">
        <v-card>
          <v-card-text class="d-flex text-h6">
           {{$t("lbl.info1")}}
          </v-card-text>
          <v-card-text class="d-flex text-h6">
            {{$t("lbl.info2")}}
          </v-card-text>
          <v-card-text class="d-flex text-h6">
            {{$t("lbl.info3")}}
          </v-card-text>
          <v-card-text class="d-flex text-h6">
            {{$t("lbl.info4")}}
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="3">
        <v-card class="mx-auto" max-width="400">
          <v-img src="../assets/doc/video.png" height="300"></v-img>
          <v-card-title>{{$t("lbl.info5")}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              href="/doc/mp4/Tanitim.mp4"
              target="_blank"
              color="teal accent-4"
              text
            >
              {{ $t("lbl.watch") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card class="mx-auto" max-width="400">
          <v-img src="../assets/doc/help.png" height="300"></v-img>
          <v-card-title>{{$t("lbl.info6")}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              href="/doc/Kadastro3bnet_Yardim_Dokumani.pdf"
              target="_blank"
              color="teal accent-4"
              text
            >
              {{ $t("lbl.download") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-divider class="ma-8"></v-divider>
    <v-row>
      <v-spacer></v-spacer>
      <v-card class="mx-auto" max-width="400">
        <v-card-title>{{$t("lbl.info7")}}</v-card-title>
      </v-card>
      <v-spacer></v-spacer>
    </v-row>
    <p class="text-center mt-6 mx-4">
      {{$t("lbl.info8")}}      
    </p>
    <v-divider class="ma-8"></v-divider>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="3">
        <v-card class="mx-auto" max-width="400">
          <v-img src="../assets/doc/01-catiarali.png" height="300"></v-img>
          <v-card-title>{{$t("lbl.info9")}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              href="/doc/01-Çatı-Aralı.dxf"
              target="_blank"
              color="teal accent-4"
              text
            >
              {{ $t("lbl.download") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card class="mx-auto" max-width="400">
          <v-img src="../assets/doc/02-gteras.png" height="300"></v-img>
          <v-card-title>{{$t("lbl.info10")}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              href="/doc/02-Gezilemeyen-Teras.dxf"
              target="_blank"
              color="teal accent-4"
              text
            >
              {{ $t("lbl.download") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card class="mx-auto" max-width="400">
          <v-img src="../assets/doc/03-villa.png" height="300"></v-img>
          <v-card-title>{{$t("lbl.info11")}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              href="/doc/03-Dubleks-Villa.dxf"
              target="_blank"
              color="teal accent-4"
              text
            >
              {{ $t("lbl.download") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="3">
        <v-card class="mx-auto" max-width="400">
          <v-img src="../assets/doc/04-girisab.png" height="300"></v-img>
          <v-card-title>{{$t("lbl.info12")}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              href="/doc/04-Giriş-AB.dxf"
              target="_blank"
              color="teal accent-4"
              text
            >
              {{ $t("lbl.download") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card class="mx-auto" max-width="400">
          <v-img src="../assets/doc/05-raster.png" height="300"></v-img>
          <v-card-title>{{$t("lbl.info13")}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              href="/doc/05-Raster-Proje.dxf"
              target="_blank"
              color="teal accent-4"
              text
            >
              {{ $t("lbl.download") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card class="mx-auto" max-width="400">
          <v-img src="../assets/doc/06-asmakat.png" height="300"></v-img>
          <v-card-title>{{$t("lbl.info14")}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              href="/doc/06-Asma-Kat.dxf"
              target="_blank"
              color="teal accent-4"
              text
            >
              {{ $t("lbl.download") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    //
  }),
};
</script>
