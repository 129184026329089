import i18n from "../i18n";

export const roles = () => [
  { text: i18n.t("lbl.admin"), value: "admin" },
  { text: i18n.t("lbl.auditor"), value: "auditor" },
  { text: i18n.t("lbl.user"), value: "user" },
];

export const categories = () => [
  { text: i18n.t("lbl.book"), value: "book" },
  { text: i18n.t("lbl.electronic"), value: "electronic" },
  { text: i18n.t("lbl.software"), value: "software" },
];

export const procedures = () => [
  { text: i18n.t("lbl.auto"), value: "auto" },
  { text: i18n.t("lbl.manual"), value: "manual" },
];

export const coordinates = () => [
  { text: i18n.t("lbl.EPSG_5253"), value: "EPSG:5253" },
  { text: i18n.t("lbl.EPSG_5254"), value: "EPSG:5254" },
  { text: i18n.t("lbl.EPSG_5255"), value: "EPSG:5255" },
  { text: i18n.t("lbl.EPSG_5256"), value: "EPSG:5256" },
  { text: i18n.t("lbl.EPSG_5257"), value: "EPSG:5257" },
  { text: i18n.t("lbl.EPSG_5258"), value: "EPSG:5258" },
];

export const buildings = () => [
  { text: i18n.t("lbl.main"), value: "main" },
  // { text: i18n.t("lbl.other"), value: "other" },
];

export const stat = () => [
  { text: i18n.t("lbl.processing"), value: "info" },
  { text: i18n.t("lbl.errored"), value: "error" },
  { text: i18n.t("lbl.processed"), value: "success" },
];

export const ticket = () => [
  { text: i18n.t("lbl.new"), value: "new" },
  { text: i18n.t("lbl.open"), value: "open" },
  { text: i18n.t("lbl.close"), value: "close" },
];

export const trxTypes = () => [
  { text: i18n.t("lbl.expense"), value: "debit" },
  { text: i18n.t("lbl.payment"), value: "credit" },
  // { text: i18n.t("lbl.refund"), value: "refund" },
];

export const trxStatus = () => [
  { text: i18n.t("lbl.submitted"), value: "submitted" },
  { text: i18n.t("lbl.approved"), value: "approved" },
];
