import Vue from "vue";
import VueRouter from "vue-router";
import Default from "../layouts/Default.vue";
import Error from "../layouts/Error.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import User from "../views/User.vue";
import Product from "../views/Product.vue";
import Vendor from "../views/Vendor.vue";
import Project from "../views/Project.vue";
import Validation from "../views/Validation.vue";
import Transaction from "../views/Transaction.vue";
import Tickets from "../views/Tickets.vue";
import Ticket from "../views/Ticket.vue";
import Menu from "../views/Menu.vue";
import Item from "../components/Item.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: Default,
    children: [
      {
        path: "/",
        name: "home",
        component: Home,
      },
      {
        path: "/login",
        name: "login",
        component: Login,
      },
      {
        path: "/register",
        name: "register",
        component: Register,
      },
      {
        path: "/profile",
        name: "profile",
        component: Register,
      },
      {
        path: "/user",
        name: "user",
        component: User,
        meta: {
          title: "nav.users",
        },
      },
      {
        path: "/product",
        name: "product",
        component: Product,
        meta: {
          title: "nav.products",
        },
      },
      {
        path: "/vendor",
        name: "vendor",
        component: Vendor,
        meta: {
          title: "nav.vendors",
        },
      },
      {
        path: "/project",
        name: "project",
        component: Project,
        meta: {
          title: "nav.projects",
        },
      },
      {
        path: "/validation/:id",
        name: "validation",
        component: Validation,
        meta: {
          title: "nav.validation",
        },
      },
      {
        path: "/transaction",
        name: "transaction",
        component: Transaction,
        meta: {
          title: "nav.account",
        },
      },
      {
        path: "/ticket",
        name: "tickets",
        component: Tickets,
        meta: {
          title: "nav.tickets",
        },
      },
      {
        path: "/ticket/:no",
        name: "ticket",
        component: Ticket,
        meta: {
          title: "nav.ticket",
        },
      },
      {
        path: "/menu",
        component: Menu,
        meta: {
          title: "nav.menu",
        },
        children: [
          {
            path: "item/:no",
            name: "item1",
            component: Item,
            props: true,
            meta: {
              title: "nav.item1",
            },
          },
          {
            path: "item/:no",
            name: "item2",
            component: Item,
            props: true,
            meta: {
              title: "nav.item2",
            },
          },
        ],
      },
      {
        path: "/about",
        name: "about",
        meta: {
          title: "nav.about",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },
    ],
  },
  {
    path: "*",
    name: "error",
    component: Error,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
