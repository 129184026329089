<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="projects"
      :items-per-page="100"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right',
      itemsPerPageOptions: [10, 25, 50, 100],
    }"
      :loading="loading"
      multi-sort
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-toolbar-title>{{ $t("lbl.projects") }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
            >
          </v-divider>
          <v-toolbar-title v-if="!auth('getUsers')" >
            <span v-if="Number(credit) || credit === 0" class="text-sm-body-1" :class=" credit < 300 ? 'red--text' : 'green--text'">
              ({{$t("lbl.balance")}}: {{ credit }},00<v-icon class="mb-1" :class="credit < 300 ? 'red--text' : 'green--text'" small> mdi-currency-try</v-icon>)
            </span>
          </v-toolbar-title>
          <v-progress-linear
            :active="progress"
            :indeterminate="progress"
            absolute
            bottom
            color="deep-orange"
          ></v-progress-linear>
          <v-spacer></v-spacer>
          <v-btn
            href="https://3dbinadogrula.tkgm.gov.tr/"
            target="_blank"
            color="success"
            :disabled="disable"
          >
            {{ $t("lbl.tkgmgml") }}
          </v-btn>
          <v-dialog
            v-model="dialog"
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="auth('createProjects')"
                color="primary"
                class="ml-4"
                v-bind="attrs"
                v-on="on"
                :disabled="disable"
              >{{
              $t("lbl.newProject")
            }}</v-btn>
            </template>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-card>
                <v-card-title>
                  <v-col>
                    <span class="headline">{{ formTitle }}</span>
                  </v-col>
                  <v-col>
                    <v-card-actions>
                      <v-btn color="blue-grey lighten-4" btn @click="fill">{{
                        $t("lbl.exampleProject")
                      }}</v-btn>
                    </v-card-actions>
                  </v-col>
                  <v-col></v-col>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.name"
                          :rules="rules.required"
                          :label="$t('lbl.name')"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="editedItem.takbisId"
                          :rules="rules.required"
                          :label="$t('lbl.takbisId')"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="editedItem.maksIdentityNumber"
                          :rules="rules.maksIdentityNumber"
                          :label="$t('lbl.maksIdentityNumber')"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="editedItem.lot"
                          :rules="rules.required"
                          :label="$t('lbl.lot')"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="editedItem.parcel"
                          :rules="rules.required"
                          :label="$t('lbl.parcel')"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-select
                          v-model="editedItem.coordinateSystem"
                          :items="coordinates"
                          :rules="rules.required"
                          :label="$t('lbl.coordinateSystem')"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-select
                          v-model="editedItem.building"
                          :items="buildings"
                          :rules="rules.required"
                          :label="$t('lbl.building')"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="editedItem.block"
                          :label="$t('lbl.block')"
                          placeholder="Varsa A veya B şeklinde"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-dialog
                          ref="confirmDatePicker"
                          v-model="dialogConfirmDate"
                          :return-value.sync="editedItem.confirmDate"
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              type="date"
                              v-model="editedItem.confirmDate"
                              :label="$t('lbl.confirmDate')"
                              readonly
                              hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-if="dialogConfirmDate"
                            v-model="editedItem.confirmDate"
                            full-width
                            format="24hr"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="dialogConfirmDate = false"
                            >
                              {{ $t('lbl.cancel') }}
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.confirmDatePicker.save(editedItem.confirmDate)"
                            >
                              {{ $t('lbl.ok') }}
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                      <v-col v-if="editedIndex === -1 && credit >= 300 && editedItem.confirmDate && valid && editedItem.takbisId !== '411529'"
                        class="d-flex justify-end"
                        cols="12"
                        sm="12"
                        md="12"
                      >
                      <span class="red--text">{{$t('lbl.bakiyeDusecek')}}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="reset"
                  >{{ $t("lbl.clear") }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialog = false"
                  >{{
                  $t("lbl.cancel")
                }}</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!valid"
                    @click="save"
                  >{{
                  $t("lbl.save")
                }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="2"
            >
              <v-text-field
                outlined
                dense
                hide-details
                v-model="filter.takbisId"
                :label="$t('lbl.takbisId')"
                :disabled="disable"
                @keydown.enter="initialize(true)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="2"
            >
              <v-text-field
                outlined
                dense
                hide-details
                v-model="filter.project"
                :label="$t('lbl.projectSearch')"
                :disabled="disable"
                @keydown.enter="initialize(true)"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="auth('getUsers')"
              cols="12"
              sm="6"
              md="2"
            >
              <v-autocomplete
                outlined
                dense
                hide-details
                v-model="filter.user"
                :items="users"
                item-text="email"
                item-value="id"
                :label="$t('lbl.user')"
                :disabled="disable"
                @change="initialize(true)"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="2"
            >
              <v-datetime-picker
                v-model="filter.start"
                :label="$t('lbl.start')"
                :clearText="$t('lbl.clear')"
                :okText="$t('lbl.ok')"
                :textFieldProps="{
                outlined: true,
                dense: true,
                hideDetails: true,
                clearable: true,
              }"
                :datePickerProps="{ locale: $i18n.locale, 'no-title': true }"
                :timePickerProps="{ format: '24hr', 'no-title': true }"
                :disabled="disable"
                @input="initialize(true)"
              ></v-datetime-picker>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="2"
            >
              <v-datetime-picker
                v-model="filter.end"
                :label="$t('lbl.end')"
                :clearText="$t('lbl.clear')"
                :okText="$t('lbl.ok')"
                :textFieldProps="{
                outlined: true,
                dense: true,
                hideDetails: true,
                clearable: true,
              }"
                :datePickerProps="{ locale: $i18n.locale, 'no-title': true }"
                :timePickerProps="{ format: '24hr', 'no-title': true }"
                :disabled="disable"
                @input="initialize(true)"
              ></v-datetime-picker>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="1"
            >
              <v-select
                outlined
                dense
                hide-details
                hidden
                v-model="filter.status"
                :items="stat"
                :label="$t('lbl.status')"
                @change="initialize(true)"
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              cols="12"
              sm="12"
              md="1"
            >
              <v-btn
                block
                color="warning"
                :disabled="disable"
                @click="refresh"
              >
                <v-icon>mdi-refresh-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:[`item.procedure`]="{ item }">
        <span>{{ getEnumText(procedures, item.procedure) }}</span>
      </template>
      <template v-slot:[`item.coordinateSystem`]="{ item }">
        <span>{{ getEnumText(coordinates, item.coordinateSystem) }}</span>
      </template>
      <template v-slot:[`item.building`]="{ item }">
        <span>{{ getEnumText(buildings, item.building) }}</span>
      </template>
      <template v-slot:[`item.confirmDate`]="{ item }">
        <span>
          {{ item.confirmDate && $luxon.fromISO(item.confirmDate).toLocaleString(fmtDate) }}
        </span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ $luxon.fromISO(item.createdAt).toLocaleString(fmtDatetime) }}
            </span>
          </template>
          {{ $luxon.fromISO(item.updatedAt).toLocaleString(fmtDatetime) }}
        </v-tooltip>
      </template>
      <template v-slot:[`item.results`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              :disabled="disable"
              @click="download(item, 'dxf')"
            >
              mdi-file-cad
            </v-icon>
          </template>
          {{ $t('lbl.projectDxf')}}
        </v-tooltip>
        <v-tooltip
          top
          v-if="item.status !== 'info'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              icon
              :color="item.status"
              :disabled="disable"
              @click="download(item, 'cad')"
            >
              mdi-domain
            </v-icon>
          </template>
          {{ $t('lbl.projectCad')}}
        </v-tooltip>
        <v-tooltip
          top
          v-if="item.status === 'success'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :color="item.status"
              :disabled="disable"
              @click="download(item, 'gml')"
            >
              <v-icon>
                mdi-office-building
              </v-icon>
            </v-btn>
          </template>
          {{ $t('lbl.projectGml')}}
        </v-tooltip>
        <v-tooltip
          top
          v-if="item.status === 'error'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="error"
              :disabled="disable"
              @click="$router.push(`/validation/${item.id}`)"
            >
              <v-icon>
                mdi-alert-circle
              </v-icon>
            </v-btn>
          </template>
          {{ $t('lbl.projectErrors')}}
        </v-tooltip>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div v-if="item.filename && item.status === 'info'">
          <v-progress-circular
            indeterminate
            color="primary"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.status"
                > {{ getProgressIcon(item) }} </v-icon>
              </template>
              {{ getProgressText(item) }}
            </v-tooltip>
          </v-progress-circular>
        </div>
        <div v-else>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                :color="item.status"
              > {{ getProgressIcon(item) }} </v-icon>
            </template>
            {{ getProgressText(item) }}
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              :disabled="disable"
              @click="openFileDialog(item)"
            > mdi-file-upload </v-icon>
          </template>
          {{ $t('lbl.projectUpload')}}
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              :disabled="disable"
              @click="editItem(item)"
            > mdi-pencil </v-icon>
          </template>
          {{ $t('lbl.projectEdit')}}
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="auth('manage')"
              v-bind="attrs"
              v-on="on"
              :disabled="disable"
              @click="deleteItem(item)"
            > mdi-delete </v-icon>
          </template>
          {{ $t('lbl.projectDelete')}}
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogFile"
      max-width="400px"
    >
      <v-form
        ref="formFile"
        v-model="validFile"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t("lbl.projectFile") }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-select
                    v-model="uploadFile.procedure"
                    :items="procedures"
                    :rules="rules.required"
                    :label="$t('lbl.procedure')"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-file-input
                    v-model="uploadFile.projectFile"
                    :rules="rules.required"
                    :placeholder="$t('lbl.projectDxf')"
                    accept=".dxf"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="reset"
            >{{ $t("lbl.clear") }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogFile = false"
            >{{ $t("lbl.cancel") }}</v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!validFile"
              @click="upload()"
            >{{ $t("lbl.upload") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { DateTime } from "luxon";
import { saveAs } from "file-saver";
import { arrayBufferToBase64, base64ToArrayBuffer } from "@/common/utils";
import { required, wholeNumber } from "@/common/validation";
import {
  procedures, coordinates, buildings, stat,
} from "@/common/enums";

export default {
  data: () => ({
    credit:"",
    disable: false,
    dialog: false,
    dialogFile: false,
    dialogConfirmDate: false,
    valid: true,
    validFile: true,
    loading: false,
    progress: false,
    options: {},
    total: 0,
    projects: [],
    users: [],
    fmtDate: DateTime.DATE_SHORT,
    fmtDatetime: DateTime.DATETIME_SHORT,
    editedIndex: -1,
    editedItem: {
      takbisId: "",
      name: "",
      coordinateSystem: "",
      lot: "",
      parcel: "",
      building: "main",
      block: "",
      maksIdentityNumber: "",
      confirmDate: null,
    },
    defaultItem: {
      takbisId: "",
      name: "",
      coordinateSystem: "",
      lot: "",
      parcel: "",
      building: "main",
      block: "",
      maksIdentityNumber: "",
      confirmDate: null,
    },
    uploadFile: {
      id: "",
      procedure: false,
      projectFile: null,
    },
    uploadFileDefault: {
      id: "",
      procedure: false,
      projectFile: null,
    },
    rules: {
      required: [required],
      maksIdentityNumber: [required, wholeNumber],
    },
    filter: {
      takbisId: null,
      name: null,
      start: null,
      end: null,
      status: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("lbl.newProject")
        : this.$t("lbl.editProject");
    },
    headers() {
      const columns = [
        { text: this.$t("lbl.takbisId"), value: "takbisId" },
        {
          text: this.$t("lbl.maksIdentityNumber"),
          value: "maksIdentityNumber",
        },
        { text: this.$t("lbl.name"), value: "name" },
        { text: this.$t("lbl.filename"), value: "filename" },
        { text: this.$t("lbl.procedure"), value: "procedure" },
        { text: this.$t("lbl.coordinateSystem"), value: "coordinateSystem" },
        { text: this.$t("lbl.lot"), value: "lot" },
        { text: this.$t("lbl.parcel"), value: "parcel" },
        { text: this.$t("lbl.building"), value: "building" },
        { text: this.$t("lbl.block"), value: "block" },
        { text: this.$t("lbl.confirmDate"), value: "confirmDate" },
        { text: this.$t("lbl.date"), value: "createdAt" },
      ];
      if (this.auth("getUsers")) {
        columns.push({ text: this.$t("lbl.user"), value: "user.email" });
      }
      columns.push(
        ...[
          {
            text: this.$t("lbl.results"),
            value: "results",
            align: "center",
            sortable: false,
          },
          {
            text: this.$t("lbl.status"),
            value: "status",
            align: "center",
            sortable: false,
          },
        ],
      );
      if (this.auth("manageProjects")) {
        columns.push({
          text: this.$t("lbl.actions"),
          value: "actions",
          align: "center",
          sortable: false,
        });
      }
      return columns;
    },
    procedures() {
      return procedures();
    },
    coordinates() {
      return coordinates();
    },
    buildings() {
      return buildings();
    },
    stat() {
      return stat();
    },
  },

  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    dialogFile(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeFileDialog();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },

  sockets: {
    processed() {
      this.initialize();
    },
  },

  methods: {
    ...mapActions("project", [
      "findProjects",
      "getProject",
      "saveProject",
      "deleteProject",
      "getProjectFile",
      "saveProjectFile",
    ]),
    ...mapActions("user", ["findUsers"]),
    ...mapActions("transaction", ["findTransactions"]),

    async initialize(search) {
      this.loading = true;
      if (search) {
        this.options.page = 1;
      }
      const filters = this.getFilters(this.options, this.filter);
      try {
        const { results, totalResults } = await this.findProjects(filters);
        this.projects = results;
        this.total = totalResults;
      } catch (error) {
        this.$toasted.error(this.getMessageText(error.message));
      } finally {
        this.loading = false;
      }

      if (this.auth("getUsers")) {
        try {
          const { results } = await this.findUsers({
            limit: 0,
          });
          this.users = results;
        } catch (error) {
          this.$toasted.error(this.getMessageText(error.message));
        }
      }

      const { id: userId } = this.sessionUser();
      this.$socket.emit("client", userId);
      
      //transaction
      try {
        const { results } = await this.findTransactions();
        this.transactions = results;
        this.credit = this.getTotal(
          results.filter((item) => item.status === "approved"),
        );
        this.creditAll = this.getTotal(results);
      } catch (error) {
        this.$toasted.error(this.getMessageText(error.message));
      } finally {
        this.loading = false;
      }
    
    },

    reset() {
      this.$refs.form.reset();
    },

    close() {
      this.reset();
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    editItem(item) {
      this.editedIndex = this.projects.indexOf(item);
      this.editedItem = { ...item };
      this.editedItem.confirmDate = this.editedItem.confirmDate
        ? this.$luxon.fromISO(this.editedItem.confirmDate).toISODate()
        : undefined;
      this.dialog = true;
    },

    deleteItem(item) {
      this.$swal({
        html: this.$t("msg.deleteText", {
          item: `${item.name} - ${item.takbisId}`,
        }),
        icon: "warning",
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("lbl.delete"),
        cancelButtonText: this.$t("lbl.cancel"),
      }).then((result) => {
        if (result.value) {
          this.disable = true;
          this.deleteProject(item.id)
            .then(() => {
              this.initialize();
              this.$toasted.success(
                this.$t("msg.delete", { item: this.$t("lbl.project") }),
              );
            })
            .catch((error) => {
              this.$toasted.error(this.getMessageText(error.message));
            })
            .finally(() => {
              this.disable = false;
            });
        }
      });
    },

    save() {
      this.dialog = false;
      this.disable = true;
      const editedItem = { ...this.editedItem };
      return this.saveProject(editedItem)
        .then(() => {
          this.initialize();
          this.$toasted.success(
            this.$t("msg.save", { item: this.$t("lbl.project") }),
          );
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        })
        .finally(() => {
          this.disable = false;
        });
    },

    fill() {
      this.editedItem.name = "Deneme";
      this.editedItem.takbisId = "411529";
      this.editedItem.maksIdentityNumber = 1111;
      this.editedItem.lot = "0";
      this.editedItem.parcel = "550";
      this.editedItem.coordinateSystem = "EPSG:5255";
      this.editedItem.building = "main";
      this.editedItem.confirmDate = "2024-04-04";
    },

    getTotal(items) {
      return items.reduce((acc, item) => acc + item.amount, 0);
    },

    download(item, extension) {
      this.disable = true;
      this.progress = true;
      this.getProjectFile([item.id, extension])
        .then(({ name, content }) => {
          const buffer = base64ToArrayBuffer(content);
          saveAs(new Blob([buffer]), name);
          this.$toasted.success(
            this.$t("msg.download", { item: this.$t("lbl.project") }),
          );
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        })
        .finally(() => {
          this.disable = false;
          this.progress = false;
        });
    },

    resetFile() {
      this.$refs.formFile.reset();
    },

    closeFileDialog() {
      this.resetFile();
      this.$nextTick(() => {
        this.uploadFile = { ...this.uploadFileDefault };
      });
    },

    openFileDialog(item) {
      const { id, procedure } = item;
      this.uploadFile = { id, procedure };
      this.dialogFile = true;
    },

    async upload() {
      this.dialogFile = false;
      this.disable = true;
      this.progress = true;
      const { id, procedure, projectFile } = this.uploadFile;
      const { name: filename } = projectFile;
      const buffer = await projectFile.arrayBuffer();
      const projectDxf = arrayBufferToBase64(buffer);
      this.saveProjectFile({
        id,
        procedure,
        filename,
        projectDxf,
      })
        .then(() => {
          this.initialize();
          this.$toasted.success(
            this.$t("msg.save", { item: this.$t("lbl.project") }),
          );
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        })
        .finally(() => {
          this.disable = false;
          this.progress = false;
        });
    },

    refresh() {
      Object.keys(this.filter).forEach((i) => {
        this.filter[i] = null;
      });
      this.initialize();
    },

    getProgressIcon(item) {
      const { status } = item;
      switch (status) {
        case "info":
          return "mdi-progress-clock";
        case "success":
          return "mdi-progress-check";
        case "error":
          return "mdi-progress-alert";
        default:
          return "mdi-alert";
      }
    },

    getProgressText(item) {
      const { status } = item;
      switch (status) {
        case "info":
          return this.$t("lbl.processing");
        case "success":
          return this.$t("lbl.processed");
        case "error":
          return this.$t("lbl.errored");
        default:
          return "";
      }
    },
  },
};
</script>

<style>
.swal2-html-container {
  font-size: 26px !important;
}
.swal2-checkbox,
.swal2-radio {
  font-size: 22px;
  color: red !important;
}
</style>
