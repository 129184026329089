<template>
  <v-data-table
    :headers="headers"
    :items="errors"
    :items-per-page="25"
    :options.sync="options"
    :server-items-length="total"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right',
      itemsPerPageOptions: [10, 25, 50, 100],
    }"
    :loading="loading"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
        color="white"
      >
        <v-toolbar-title>{{ $t("lbl.validation") }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          :disabled="disable"
          @click="$router.push('/project', () => {})"
        >
          <v-icon>
            mdi-keyboard-backspace
          </v-icon>
          {{ $t("lbl.projects") }}
        </v-btn>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    disable: false,
    loading: false,
    options: {},
    total: 0,
    errors: [],
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("lbl.no"), value: "no" },
        { text: this.$t("lbl.errorId"), value: "errorId" },
        { text: this.$t("lbl.error"), value: "error" },
      ];
    },
  },

  watch: {
    options: {
      handler() {
        const { id } = this.$route.params;
        this.initialize(id);
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions("project", ["getProjectErrors"]),

    async initialize(id) {
      this.loading = true;
      try {
        const slugs = [id, this.$i18n.locale];
        const params = this.getFilters(this.options, {});
        const { total, errors } = await this.getProjectErrors({ slugs, params });
        this.errors = errors;
        this.total = total;
      } catch (error) {
        this.$toasted.error(this.getMessageText(error.message));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
