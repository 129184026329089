<template>
  <div class="about">
    <h1>This is item page {{ no }}</h1>
  </div>
</template>

<script>
export default {
  props: ["no"],
};
</script>
