import service from "./axios";

const ApiService = {
  find(resource, params) {
    return service.get(resource, params);
  },
  get(resource, slug, params) {
    const path = slug ? `${resource}/${slug}` : resource;
    return service.get(path, params);
  },
  post(resource, body) {
    return service.post(resource, body);
  },
  postSlug(resource, slug, body) {
    return service.post(`${resource}/${slug}`, body);
  },
  postQuery(resource, query, body) {
    return service.post(resource, body, {
      params: query,
    });
  },
};

export default ApiService;

export const AuthService = {
  register(body) {
    return ApiService.post("auth/register", body);
  },
  login(body) {
    return ApiService.post("auth/login", body);
  },
  logout(body) {
    return ApiService.post("auth/logout", body);
  },
  forgotPassword(body) {
    return ApiService.post("auth/forgot-password", body);
  },
  resetPassword(query, body) {
    return ApiService.postQuery("auth/reset-password", query, body);
  },
  sendVerificationEmail(body) {
    return ApiService.post("auth/send-verification-email", body);
  },
  verifyEmail(query, body) {
    return ApiService.postQuery("auth/verify-email", query, body);
  },
};

export const UserService = {
  find(params) {
    return ApiService.find("user/findUsers", { params });
  },
  get(id) {
    return ApiService.get("user/getUser", id);
  },
  create(body) {
    return ApiService.post("user/createUser", body);
  },
  update(id, body) {
    return ApiService.postSlug("user/updateUser", id, body);
  },
  delete(id) {
    return ApiService.postSlug("user/deleteUser", id);
  },
  getProfile() {
    return ApiService.get("user/getProfile");
  },
  updateProfile(body) {
    return ApiService.post("user/updateProfile", body);
  },

};

export const ProductService = {
  find(params) {
    return ApiService.find("product/findProducts", { params });
  },
  get(id) {
    return ApiService.get("product/getProduct", id);
  },
  create(body) {
    return ApiService.post("product/createProduct", body);
  },
  update(id, body) {
    return ApiService.postSlug("product/updateProduct", id, body);
  },
  delete(id) {
    return ApiService.postSlug("product/deleteProduct", id);
  },
};

export const VendorService = {
  find(params) {
    return ApiService.find("vendor/findVendors", { params });
  },
  get(id) {
    return ApiService.get("vendor/getVendor", id);
  },
  create(body) {
    return ApiService.post("vendor/createVendor", body);
  },
  update(id, body) {
    return ApiService.postSlug("vendor/updateVendor", id, body);
  },
  delete(id) {
    return ApiService.postSlug("vendor/deleteVendor", id);
  },
};

export const TicketService = {
  find(params) {
    return ApiService.find("ticket/findTickets", { params });
  },
  get(id) {
    return ApiService.get("ticket/getTicket", id);
  },
  create(body) {
    return ApiService.post("ticket/createTicket", body);
  },
  update(id, body) {
    return ApiService.postSlug("ticket/updateTicket", id, body);
  },
  delete(id) {
    return ApiService.postSlug("ticket/deleteTicket", id);
  },
  saveMessage(body) {
    return ApiService.post("ticket/saveMessage", body);
  },
};

export const ProjectService = {
  find(params) {
    return ApiService.find("project/findProjects", { params });
  },
  get(id) {
    return ApiService.get("project/getProject", id);
  },
  create(body) {
    return ApiService.post("project/createProject", body);
  },
  update(id, body) {
    return ApiService.postSlug("project/updateProject", id, body);
  },
  delete(id) {
    return ApiService.postSlug("project/deleteProject", id);
  },
  getProjectFile(project) {
    return ApiService.get("project/getProjectFile", project);
  },
  saveProjectFile(id, body) {
    return ApiService.postSlug("project/saveProjectFile", id, body);
  },
  getProjectErrors(project, params) {
    return ApiService.get("project/getProjectErrors", project, params);
  },
};

export const TransactionService = {
  find(params) {
    return ApiService.find("transaction/findTransactions", { params });
  },
  get(id) {
    return ApiService.get("transaction/getTransaction", id);
  },
  create(body) {
    return ApiService.post("transaction/createTransaction", body);
  },
  update(id, body) {
    return ApiService.postSlug("transaction/updateTransaction", id, body);
  },
};
