export const email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const password = /^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z]{8,}$/;
export const domain = /^(?:\w+:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?(?:m\.)?([^:/\n]+)/;
export const url = /^(?:\w+:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?(?:m\.)?(.*)/;

export default {
  email,
  password,
  domain,
  url,
};
