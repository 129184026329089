<template>
  <div>
    <Vendor />
  </div>
</template>
<script>
import Vendor from "@/components/Vendor.vue";

export default {
  components: {
    Vendor,
  },
};
</script>
