import { ProductService } from "@/common/api.service";

const initialState = {};

const state = { ...initialState };

const getters = {};

const mutations = {};

const actions = {
  findProducts(_, params) {
    return ProductService.find(params).then(({ data }) => data);
  },
  getProduct(_, id) {
    return ProductService.get(id).then(({ data }) => data);
  },
  saveProduct(_, {
    id, date, createdAt, updatedAt, ...body
  }) {
    const request = id
      ? ProductService.update(id, body)
      : ProductService.create(body);

    return request.then(({ data }) => data);
  },
  deleteProduct(_, id) {
    return ProductService.delete(id).then(({ data }) => data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
