<template>
  <v-container
    fill-height
    fluid
  >
    <v-toolbar
      flat
      color="white"
      class="mb-4"
    >
      <v-toolbar-title>{{ $t("lbl.ticket") }}</v-toolbar-title>
      <v-divider
        class="mx-4"
        inset
        vertical
      ></v-divider>
      <v-toolbar-title>{{ no }} - {{ getEnumText(ticket, status) }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ subject }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="info"
        @click="$router.push('/ticket', () => {})"
      >
        <v-icon>
          mdi-keyboard-backspace
        </v-icon>
        {{ $t("lbl.tickets") }}
      </v-btn>
    </v-toolbar>
    <v-row
      align="center"
      justify="center"
      v-for="(message, i) in messages"
      :key="i"
    >
      <v-col
        cols="12"
        sm="1"
        class="text-center"
      >
        <v-chip
          :color="message.user.role === 'admin' ? 'warning' : 'primary'"
          outlined
          pill
        >
          <v-icon left>
            mdi-account-outline
          </v-icon>
          {{ message.user.email.split("@")[0] }}
        </v-chip>
        <v-chip
          label
          small
          color="white"
        >
          {{ $luxon.fromISO(message.date).toLocaleString(fmtDatetime) }}
        </v-chip>
      </v-col>
      <v-col
        cols="12"
        sm="9"
      >
        <v-card
          elevation="2"
          class="mb-4"
        >
          <v-card-text v-html="message.text">
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="status!=='close'">
      <v-col
        cols="12"
        sm="1"
        offset-sm="1"
      >
      </v-col>
      <v-col
        cols="12"
        sm="9"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-textarea
                v-model="editedItem.text"
                :label="$t('lbl.message')"
              ></v-textarea>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
            >{{ $t("lbl.send") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { DateTime } from "luxon";
import { ticket } from "@/common/enums";

export default {
  data: () => ({
    no: 0,
    subject: "",
    status: "",
    messages: [],
    editedItem: {
      text: "",
    },
    defaultItem: {
      text: "",
    },
    fmtDatetime: DateTime.DATETIME_SHORT,
  }),

  computed: {
    ticket() {
      return ticket();
    },
  },

  created() {
    this.no = this.$route.params.no;
    this.initialize(this.no);
  },

  sockets: {
    ticket() {
      this.refresh();
    },
  },

  methods: {
    ...mapActions("ticket", ["getTicket", "saveMessage"]),

    async initialize(no) {
      try {
        const {
          ticket: { subject, status },
          messages,
        } = await this.getTicket(no);
        this.subject = subject;
        this.status = status;
        this.messages = messages;
      } catch (error) {
        this.$toasted.error(this.getMessageText(error.message));
      }
    },

    save() {
      const { text } = this.editedItem;
      if (!text) {
        return;
      }
      const { no } = this;
      this.saveMessage({ no, text })
        .then(() => {
          this.initialize(no);
          this.editedItem = { ...this.defaultItem };
          this.$toasted.success(
            this.$t("msg.save", { item: this.$t("lbl.ticket") }),
          );
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        });
    },

    refresh() {
      const { no } = this;
      this.initialize(no);
    },
  },
};
</script>
