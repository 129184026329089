import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";
import user from "./user.module";
import product from "./product.module";
import vendor from "./vendor.module";
import project from "./project.module";
import transaction from "./transaction.module";
import ticket from "./ticket.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pageTitle: "",
  },
  getters: {
  },
  mutations: {
    setTitle(state, data) {
      state.pageTitle = data;
    },
  },
  actions: {
  },
  modules: {
    auth,
    user,
    product,
    vendor,
    project,
    transaction,
    ticket,
  },
});
