<template>
  <v-list>
    <div
      v-for="(item, i) in items"
      :key="i"
    >
      <template v-if="!item.hide && item.group">
        <v-list-group color="">
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="({ icon, title, to, right, hide }, i) in item.group"
            :key="i"
            :to="to"
            v-show="!hide && auth(right)"
            router
            exact
            class="px-8"
          >
            <v-list-item-action>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
      <template v-else>
        <v-list-item
          :to="item.to"
          v-show="!item.hide && auth(item.right)"
          router
          exact
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </div>
  </v-list>
</template>

<script>
export default {
  computed: {
    items() {
      return [
        {
          icon: "mdi-apps",
          title: this.$t("nav.home"),
          to: "/",
        },
        {
          icon: "mdi-account",
          title: this.$t("nav.users"),
          to: "/user",
          right: "get",
        },
        {
          icon: "mdi-devices",
          title: this.$t("nav.products"),
          to: "/product",
          right: "getProducts",
        },
        {
          icon: "mdi-store",
          title: this.$t("nav.vendors"),
          to: "/vendor",
          right: "getVendors",
        },
        {
          icon: "mdi-office-building-marker",
          title: this.$t("nav.projects"),
          to: "/project",
        },
        {
          icon: "mdi-currency-try",
          title: this.auth("manageTransactions") ? this.$t("nav.accounts") : this.$t("nav.account"),
          to: "/transaction",
        },
        {
          icon: "mdi-face-agent",
          title: this.$t("nav.tickets"),
          to: "/ticket",
          right: "getTickets",
        },
        {
          icon: "mdi-menu",
          title: this.$t("nav.menu"),
          group: [
            {
              icon: "mdi-menu-open",
              title: this.$t("nav.item1"),
              to: "/menu/item/1",
            },
            {
              icon: "mdi-menu-open",
              title: this.$t("nav.item2"),
              to: "/menu/item/2",
            },
          ],
          hide: true,
        },
        {
          icon: "mdi-chart-bubble",
          title: this.$t("nav.about"),
          to: "/about",
          hide: true,
        },
      ];
    },
  },
};
</script>
