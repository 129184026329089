import { ProjectService } from "@/common/api.service";

const initialState = {};

const state = { ...initialState };

const getters = {};

const mutations = {};

const actions = {
  findProjects(_, params) {
    return ProjectService.find(params).then(({ data }) => data);
  },
  getProject(_, id) {
    return ProjectService.get(id).then(({ data }) => data);
  },
  saveProject(_, {
    id, filename, fileext, user, procedure, status, createdAt, updatedAt, ...body
  }) {
    const request = id
      ? ProjectService.update(id, body)
      : ProjectService.create(body);

    return request.then(({ data }) => data);
  },
  deleteProject(_, id) {
    return ProjectService.delete(id).then(({ data }) => data);
  },
  getProjectFile(_, slugs) {
    return ProjectService.getProjectFile(`${slugs.join("/")}`).then(({ data }) => data);
  },
  saveProjectFile(_, { id, ...body }) {
    return ProjectService.saveProjectFile(id, body).then(({ data }) => data);
  },
  getProjectErrors(_, { slugs, params }) {
    return ProjectService.getProjectErrors(`${slugs.join("/")}`, { params }).then(({ data }) => data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
