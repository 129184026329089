<template>
  <div>
    <Tickets />
  </div>
</template>
<script>
import Tickets from "@/components/Tickets.vue";

export default {
  components: {
    Tickets,
  },
};
</script>
